@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "InterMedium";
  src: url("./fonts/Inter-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "InterSemiBold";
  src: url("./fonts/Inter-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "InterBold";
  src: url("./fonts/Inter-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "MontserratMedium";
  src: url("./fonts/Montserrat-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "CormorantGaramondBold";
  src: url("./fonts/CormorantGaramond-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PTSerifCaptionItalic";
  src: url("./fonts/PTSerifCaption-Italic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PoppinsLight";
  src: url("./fonts/Poppins-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PoppinsMedium";
  src: url("./fonts/Poppins-Medium.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: url("./fonts/Poppins-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "PoppinsBold";
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Visby";
  src: url("./fonts/VisbyCF-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "VisbySemiBold";
  src: url("./fonts/VisbyCF-SemiBold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "VisbyBold";
  src: url("./fonts/VisbyCF-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "VisbyMedium";
  src: url("./fonts/VisbyCF-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "VisbyExtraBold";
  src: url("./fonts/VisbyCF-ExtraBold.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "VisbyThin";
  src: url("./fonts/VisbyCF-Thin.otf") format("opentype");
  font-weight: 400;
}