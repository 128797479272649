.ant-picker {
  background-color: #292f37;
  border: 1px solid #292f37;
  min-width: 170px;
  min-height: 51px;
}

.ant-picker-status-error.ant-picker {
  background-color: #292f37;
  border-color: #292f37;
}

.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background-color: #292f37;
}

.ant-picker-cell-inner::selection {
  color: #00fca3;
}
.ant-picker-body {
  background-color: #292f37;
  color: #00fca3;
}

.ant-picker-cell-in-view {
  color: #767676;
}

.ant-picker-suffix {
  color: #767676;
}

.ant-picker-footer {
  color: #ffffff;
  background-color: #292f37;
  border: 1px solid #131314;
}
.ant-picker-header {
  color: #ffffff;
  background-color: #292f37;
  border: 1px solid #131314;
  border-top: 1px solid #131314;
}

.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #131314;
}

.ant-picker-input {
  font: 14px var(--visby);
  cursor: pointer;
}

.ant-picker-input input {
  color: #ffffff;
  font: 14px var(--visby);
}

.ant-picker-header-view {
  color: #767676;
  font-family: var(--visby-medium);
}

.ant-picker-panel {
  border: 1px #292f37;
}

.ant-picker-cell .ant-picker-cell-inner:hover {
  background-color: #00fca3;
}
.ant-picker-header button {
  color: #767676;
}

.ant-picker-header button:hover {
  color: #00fca3;
}

/* ant-card */
.ant-card {
  border-radius: 22px;
  background-color: #ffffff;
  width: 600px;
  height: 340px;
}

.ant-picker-footer {
  font-family: var(--visby-medium);
}

.ant-picker-cell .ant-picker-cell-inner:hover,
.ant-picker-cell .ant-picker-cell-inner:active,
.ant-picker-cell .ant-picker-cell-inner:focus {
  background: #00fca3 !important;
}

.ant-picker-content td:hover .ant-picker-cell-inner {
  background: #00fca3 !important;
}

.ant-picker-content > thead > tr > th {
  color: #ffffff;
  opacity: 0.7;
}

.ant-picker-body {
  font-family: var(--visby-medium);
}

.ant-card-bordered {
  border: 1px solid transparent;
}

.ant-card-body {
  border-top: 1px solid #fff;
  padding: 0 29px;
}

.ant-card-head-title {
  font-family: var(--visby-semiBold);
  line-height: 33px;
  font-size: 25px;
  color: hsla(244, 31%, 20%, 1);
  padding: 0 0 14px 0;
  white-space: inherit;
  text-align: center;
}

@media only screen and (max-width: 480px) {
  .ant-card-head-title {
    font-size: 19px;
  }
}

.ant-typography {
  font-family: var(--visby);
  font-size: 17px;
  line-height: 28px;
  overflow-wrap: break-word;
  color: hsla(244, 31%, 20%, 1);
}

.ant-btn {
  font-family: var(--visby-medium);
  font-size: 14px;
  font-weight: 400;
}

@media only screen and (max-width: 660px) {
  .ant-modal {
    max-width: calc(100vw - 40px) !important;
  }
}

@media only screen and (max-width: 410px) {
  .ant-modal {
    max-width: calc(100vw - 32px) !important;
  }
}

.ant-form-item {
  margin-bottom: 22px;
}

.ant-form-item-label > label {
  color: #ffffff;
  font-family: var(--visby);
  font-size: 13px;
  margin-top: 22px;
}

.client-modal .ant-form-item-label > label {
  color: #252343;
  height: 40px;
  align-items: center;
}

.client-modal .ant-rate {
  width: 210px;
  display: flex;
  justify-content: center;
  height: 55px;
  background-color: #292f37;
  border-radius: 10px;
  padding: 10px;
}

.client-modal .ant-rate-star:not(:last-child) {
  margin-right: 15px;
}

.ant-select-selection-item {
  color: #ffffff;
  font-family: var(--visby);
  font-size: 14px;
}

.ant-input {
  color: #ffffff;
  font-family: var(--visby);
  font-size: 14px;
  background-color: #292f37;
  border: 1px #292f37;
  min-height: 51px;
}

.ant-input:focus,
.ant-input:active,
.ant-input:hover {
  border-color: #00fca3;
  outline: 0;
  -webkit-box-shadow: 0 0 0 1px #292f37;
  box-shadow: 0 0 0 1px #00fca3;
  background-color: #292f37;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #292f37 inset;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
}

/* PhonePrefixSelector  dropdown menu list*/
.ant-select-item-option-active:hover {
  background-color: #292f37;
}

.ant-select-item-option-content {
  color: #ffffff;
  font: 14px var(--visby);
}
.ant-select-dropdown {
  background: #292f37;
  padding: 7px !important;
}

.ant-select-dropdown:hover .ant-select-item .ant-select-item-option {
  background: #292f37;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: #292f37;
}

.ant-select .ant-select-focused {
  color: #ffffff;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #292f37;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #292f37;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0px #292f37;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  background-color: #292f37;
  border-color: #292f37 !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #292f37;
  border: 1px solid #292f37;
}

.ant-input-group-addon:hover {
  border: 1px solid #00fca3;
}

.ant-input-group-addon:active {
  border: 1px solid #00fca3;
}

.ant-form-item-explain {
  font-family: var(--visby);
}

.ant-form-item-required {
  color: #ffffff;
  font: 20px/28px var(--visby-bold);
}

textarea > .ant-input {
  background-color: #292f37;
  border-radius: 10px;
}

.ant-select-selector {
  color: #252342;
  font: 13px var(--visby);
}

.ant-form-item-explain {
  color: #ffffff;
  font-family: var(--visby);
}

/* Upload */
.ant-upload-list-text-container {
  margin-top: 12px;
}

.ant-upload-list-item-name {
  font-family: var(--visby);
  color: #252342;
  font-size: 14px;
}

/* RadioGroup */
span.ant-radio + * {
  padding-left: 7px;
}

.ant-radio-wrapper {
  color: hsla(244, 31%, 20%, 1);
  font-family: var(--visby-bold);
  font-size: 14px;
}

.ant-radio-group {
  width: 100%;
  margin-bottom: 12px;
}

.ant-select-dropdown {
  padding: 0;
}

.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #00fca3;
}

.ant-radio-inner {
  background-color: #292f37;
  width: 30px;
  height: 30px;
  color: hsl(244, 31%, 20%);
  border-color: #292f37;
}

.ant-radio-inner::after {
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  background-color: #00fca3;
  border-color: #00fca3;
}

/* Checkbox */
.ant.checkbox {
  border-color: red;
}

.ant-checkbox-wrapper {
  font-size: 14px;
  line-height: 22px;
}

.ant-checkbox + span {
  padding-left: 14px;
  color: #ffffff;
  font-family: var(--visby);
  align-self: center;
  opacity: 0.7;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #9fa8c2;
  border-color: #292f37;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00fca3;
  border-color: #9fa8c2;
}

.ant-checkbox-inner {
  width: 28px;
  height: 28px;
  background-color: #292f37;
  border-color: #292f37;
  border-radius: 10px;
}

.ant-checkbox-inner::after {
  top: 10px;
  left: 6px;
  width: 10px;
  height: 17px;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border-width: 2px;
}

/* ProgressBar */
.ant-progress-bg {
  background-color: #00fca3;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: #00fca3;
}

/* Badge */
.ant-badge-dot {
  width: 18px;
  min-height: 18px;
  top: 2px;
  right: 2px;
}

/* Badge.Ribbon */
.ant-ribbon {
  top: -10px;
  font-family: var(--visby-medium);
}

/* Ant table */
.ant-table-content {
  font-family: var(--visby-medium);
}

/* Divider  */
.ant-divider {
  font-family: var(--visby-semiBold);
}

.ant-divider-inner-text {
  padding: 0 8px;
}

/* Slider */
.ant-slider-mark-text {
  font-family: var(--visby-semiBold);
  color: #606265;

  width: 120px;
}

.ant-slider-mark-text:last-child {
  margin-left: -50px;
}

.ant-slider-mark-text:first-child {
  margin-left: 40px;
}

.ant-slider-mark-text-active {
  color: #ffffff;
}

.ant-tabs-dropdown-menu {
  background-color: #141516;
}

.ant-tabs-dropdown-menu-item {
  background-color: #141516;

  color: #00fca3;
}
.ant-tabs-dropdown-menu-item:hover {
  background-color: transparent;
}

.ant-input-group-addon {
  background-color: #292f37;
  border: 1px #292f37;
}

.ant-select-arrow {
  color: #ffffff;
}

.ant-input:disabled {
  background-color: #292f37;
  color: #ffffff;
  opacity: 0.7;
}

.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input {
  background-color: #292f37;
  border-color: #ff4d4f;
}

.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:hover {
  background-color: #292f37;
}

.ant-input-group {
  min-height: 51px;
}

.ant-picker-clear {
  background-color: #292d34;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 0px;
}

/* Admin page */
.internal-modal .ant-form-item-label > label {
  color: #252343;
}

.internal-modal .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00fca3;
  border-color: #00fca3;
}

.internal-modal .ant-checkbox-checked::after {
  border-color: transparent;
}

.internal-modal .ant-checkbox + span {
  color: #252343;
  font: 13px var(--visby);
  opacity: 1;
}

.internal-table .ant-table {
  background-color: #141516;
  color: #ffff;
}

.internal-table .ant-table-thead > tr > th {
  background-color: #000000;
  color: #ffff;
  opacity: 0.7;
}

.internal-table .ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #141516;
  color: #ffff;
}

.internal-table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #141516;
  transition: none;
}

.internal-table .ant-table-thead > tr > th {
  border-bottom: 1px solid #000000;
  transition: none;
}

.internal-button {
  font-size: 13px;
}

.internal-button > span {
  color: #141516;
}

.client-modal .ant-form-item-label > label {
  color: #252343;
}

.client-modal .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00fca3;
  border-color: #00fca3;
}

.client-modal .ant-checkbox-checked::after {
  border-color: transparent;
}

.client-modal .ant-checkbox + span {
  color: #252343;
  font: 13px var(--visby);
  opacity: 1;
}

.client-table .ant-table {
  background-color: #141516;
  color: #ffff;
  border-radius: 20px !important;
}

.client-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:first-child {
  border-top-left-radius: 20px;
}

.client-table
  .ant-table-container
  table
  > thead
  > tr:first-child
  th:last-child {
  border-top-right-radius: 20px;
}

.client-table .ant-table-thead > tr > th {
  background-color: #000000;
  color: #ffff;
}

.client-table .ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: #141516;
  color: #ffff;
  border-radius: 20px;
  border-bottom: 0;
}

.client-table .ant-table-tbody > tr > td {
  border-bottom: none;
  transition: none;
}

.client-table .ant-table-thead > tr > th {
  border-bottom: 1px solid #000000;
  transition: none;
}

.client-table .ant-table-content::-webkit-scrollbar-thumb,
.ant-table-content::-webkit-scrollbar-track,
.ant-table-content::-webkit-scrollbar {
  background: #141516;
  border: 1px solid #141516;
}

.client-table .ant-table-content::-webkit-scrollbar-thumb {
  background: #292f37;
  border: 1px solid #292f37;
}

.client-table .ant-tag {
  margin-right: 0;
}

.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  display: flex;
  align-items: flex-start;
}

.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  margin-top: 7px;
}
