@import "reset.css";
@import "fonts.css";
@import "colors.css";
@import "antd-override.css";

/* fonts */
:root {
  --inter: "Inter", sans-serif;
  --inter-medium: "InterMedium", sans-serif;
  --inter-semiBold: "InterSemiBold", sans-serif;
  --inter-bold: "InterBold", sans-serif;
  --poppins-light: "PoppinsLight", sans-serif;
  --poppins: "Poppins", sans-serif;
  --poppins-medium: "PoppinsMedium", sans-serif;
  --poppins-semiBold: "PoppinsSemiBold", sans-serif;
  --poppins-bold: "PoppinsBold", sans-serif;
  --montserrat-medium: "MontserratMedium", sans-serif;
  --gormorantGaramond-bold: "CormorantGaramondBold", sans-serif;
  --ptSerifCaption-italic: "PTSerifCaptionItalic", sans-serif;
  --visby-semiBold: "VisbySemiBold", sans-serif;
  --visby-bold: "VisbyBold", sans-serif;
  --visby-medium: "VisbyMedium", sans-serif;
  --visby-extraBold: "VisbyExtraBold", sans-serif;
  --visby: "Visby", sans-serif;
  --visby-thin: "VisbyThin", sans-serif;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(to right, #fff, #e4e4e4);
  border: 1px solid #aaa;
}

::-webkit-scrollbar-thumb:active {
  background: linear-gradient(to right, #22add4, #1e98ba);
}
